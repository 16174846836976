import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { colors, font } from '../consts/style';
import { ContainerCompact } from './Elements';
import Fade from '../components/Fade';
import useImages from '../hooks/useImages';
import mq from '../style/mq';

const Wrapper = styled(ContainerCompact)`
  .fade {
    display: flex;
    flex-direction: column;
  }
`;
export const Plan = styled(Link)`
  margin-top: 4rem;
  position: relative;
  color: white;
  &:hover {
    color: white;
  }

  .inner {
    width: 100%auto;
    padding-bottom: 56%;
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: ${colors.blue};
    opacity: 0.2;
  }

  .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    padding: 3.2rem 4rem;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 400px) {
      text-align: center;
      justify-content: center;
    }

    h2 {
      text-transform: uppercase;
      margin-bottom: 0;
    }
    p {
      color: ${colors.grey};
      margin: 0;
    }

    button {
      ${font.button}
      border: .1rem solid white;
      background: transparent;
    }
  }
`;

const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

PlansList.propTypes = {
  plans: PropTypes.array.isRequired,
};

export default function PlansList({ plans }) {
  const images = useImages();
  return (
    <Wrapper>
      {plans &&
        plans.map((p) => (
          <Fade key={p.slug}>
            <Plan title={p.title} to={`/residences/${p.slug}`}>
              <div className="inner">
                <Image
                  fluid={images[p.featuredImage]}
                  backgroundColor={colors.blue}
                />
              </div>
              <div className="overlay"></div>
              <div className="details">
                <div className="details-text">
                  <h2>{p.title}</h2>
                  <p>{p.size}</p>
                </div>
                <div className="details-button">
                  <button>
                    <span>Read more</span>
                  </button>
                </div>
              </div>
            </Plan>
          </Fade>
        ))}
    </Wrapper>
  );
}
