import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Fade from '../components/Fade';

import { colors } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 14.4rem;
  ${mq.mobile`
    margin-bottom: 9rem;
  `}
`;

FullwidthImage.propTypes = {
  image: PropTypes.object.isRequired,
};

export default function FullwidthImage({ image }) {
  return (
    <Fade>
      <Wrapper>
        <Img fluid={image} backgroundColor={colors.blue} />
      </Wrapper>
    </Fade>
  );
}
