import React from 'react';

import SEO from '../components/SEO';
import PageWrapper from '../containers/PageWrapper';
import PageHeading from '../components/PageHeading';
import PageHeroStatic from '../components/PageHeroStatic';
import FullwidthImage from '../components/FullwidthImage';
import PlansList from '../components/PlansList';
import useImages from '../hooks/useImages';

import { residences } from '../consts/data';
import { plansArray } from '../consts/plans';

const Residences = () => {
  const images = useImages();
  return (
    <PageWrapper>
      <SEO
        title="Residences | Call The 18th Home | Penrose Grand Del Mar"
        description="Your new home is surrounded by lush fairways that wind through a serene canyon preserve of the Grand Del Mar."
      />
      <PageHeading
        title={residences.heading.title}
        body={residences.heading.body}
        wd="54rem"
        wt="48rem"
      />
      <PageHeroStatic
        title={residences.hero.content.title}
        body={residences.hero.content.body}
        image={images['residences_hero']}
      />
      <FullwidthImage image={images['residences_fullwidth']} />
      <PageHeading
        title="Residences"
        body="With sweeping views of the golf course and large open layouts, each property offers the ultimate in resort living."
        compact
      />
      <PlansList plans={plansArray} />
    </PageWrapper>
  );
};

export default Residences;
